<template>
  <div class="p-fluid">
    <form @submit.prevent="onReset">
      <p>
        {{ $t("reset.text") }}&nbsp;<strong>{{ username }}</strong>
      </p>
      <div class="p-field">
        <label for="new-password">{{ $t("reset.field_password") }}</label>
        <Password
          v-model="password"
          aria-label="new-password"
          aria-describedby="new-password-error"
          toggle-mask
          :placeholder="transition.passwordPlaceholder"
          :feedback="true"
          :class="{ 'p-invalid': errors['password'] }"
          :disabled="isRequesting"
        />
        <small
          v-if="errors['password']"
          id="new-password-error"
          class="p-error"
          role="alert"
          aria-live="polite"
          >{{ $t("reset.field_password_error") }}</small
        >
      </div>
      <div class="p-field">
        <label for="verify-password">{{
          $t("reset.field_password_verify")
        }}</label>
        <Password
          v-model="verifyPassword"
          aria-label="verify-password"
          aria-describedby="verify-password-error"
          toggle-mask
          :placeholder="transition.passwordVerifyPlaceholder"
          :feedback="false"
          :class="{ 'p-invalid': errors['verifyPassword'] }"
          :disabled="isRequesting"
        />
        <small
          v-if="errors['verifyPassword']"
          id="verify-password-error"
          class="p-error"
          role="alert"
          aria-live="polite"
          >{{ $t("reset.field_password_verify_error") }}</small
        >
      </div>
      <Button
        class="p-mt-4 p-button-lg"
        type="submit"
        :label="transition.submitButton"
        :disabled="isRequesting"
      />
    </form>
  </div>
</template>

<script>
import { computed, ref } from "@vue/reactivity";
import Button from "primevue/button";
import Password from "primevue/password";
import { useToast } from "primevue/usetoast";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";

import { TOAST_LIFE } from "../../config";
import { assertPassword } from "../../service/assert";
import { USER_RESET_PASSWORD } from "../../store/modules/auth/auth.actions.type";
import { GET_LOADING_STATUS } from "../../store/modules/global/global.getters.type";

export default {
  name: "UserResetPassword",
  components: {
    Button,
    Password,
  },
  setup() {
    const store = useStore();
    const toast = useToast();
    const route = useRoute();
    const router = useRouter();
    const { t } = useI18n();

    const password = ref("");
    const verifyPassword = ref("");
    const errors = ref([]);
    const isRequesting = computed(() => store.getters[GET_LOADING_STATUS]);
    const { username, token } = route.params;
    const transition = {
      submitButton: t("reset.submit_button"),
      passwordPlaceholder: t("reset.field_password_placeholder"),
      passwordVerifyPlaceholder: t("reset.field_password_verify_placeholder"),
    };

    async function onReset() {
      if (validateInputs()) {
        await store.dispatch(USER_RESET_PASSWORD, {
          token: token,
          username: username,
          password: password.value,
        });
        await router.push({ name: "login" });
        toast.add({
          severity: "success",
          summary: t("common.success"),
          detail: t("reset.toast_success"),
          life: TOAST_LIFE,
        });
      }
    }

    function validateInputs() {
      errors.value = [];
      if (!assertPassword(password.value)) {
        errors.value["password"] = true;
      }
      if (password.value !== verifyPassword.value) {
        errors.value["verifyPassword"] = true;
      }
      return Object.keys(errors.value).length === 0;
    }
    return {
      password,
      verifyPassword,
      errors,
      username,
      isRequesting,
      onReset,
      transition,
    };
  },
};
</script>
