<template>
  <the-layout :title="title">
    <user-reset-password />
  </the-layout>
</template>

<script>
import { useI18n } from "vue-i18n";

import TheLayout from "../components/layout/TheLayout";
import UserResetPassword from "../components/resetPassword/UserResetPassword";

export default {
  name: "ResetPassword",
  components: {
    UserResetPassword,
    TheLayout,
  },
  setup() {
    const { t } = useI18n();
    return {
      title: t("reset.title"),
    };
  },
};
</script>
